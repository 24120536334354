/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMessageStatusRecordV10,
    KvintaMessageStatusRecordV10FromJSON,
    KvintaMessageStatusRecordV10FromJSONTyped,
    KvintaMessageStatusRecordV10ToJSON,
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
    KvintaQueueAction,
    KvintaQueueActionFromJSON,
    KvintaQueueActionFromJSONTyped,
    KvintaQueueActionToJSON,
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
    KvintaResourceChanges,
    KvintaResourceChangesFromJSON,
    KvintaResourceChangesFromJSONTyped,
    KvintaResourceChangesToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationResponseListMessageStatusRecordV10
 */
export interface KvintaOperationResponseListMessageStatusRecordV10 {
    /**
     * 
     * @type {Array<KvintaMessageStatusRecordV10>}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    data?: Array<KvintaMessageStatusRecordV10> | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    status: KvintaOperationStatus;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    executionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    rootExecutionId?: string | null;
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    resource?: KvintaResource | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    duplicate: boolean;
    /**
     * 
     * @type {KvintaResourceChanges}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    resourceChanges?: KvintaResourceChanges | null;
    /**
     * 
     * @type {KvintaQueueAction}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    queueAction?: KvintaQueueAction | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaOperationResponseListMessageStatusRecordV10
     */
    continued?: boolean | null;
}

export function KvintaOperationResponseListMessageStatusRecordV10FromJSON(json: any): KvintaOperationResponseListMessageStatusRecordV10 {
    return KvintaOperationResponseListMessageStatusRecordV10FromJSONTyped(json, false);
}

export function KvintaOperationResponseListMessageStatusRecordV10FromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationResponseListMessageStatusRecordV10 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(KvintaMessageStatusRecordV10FromJSON)),
        'status': KvintaOperationStatusFromJSON(json['status']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'executionId': !exists(json, 'executionId') ? undefined : json['executionId'],
        'rootExecutionId': !exists(json, 'rootExecutionId') ? undefined : json['rootExecutionId'],
        'resource': !exists(json, 'resource') ? undefined : KvintaResourceFromJSON(json['resource']),
        'duplicate': json['duplicate'],
        'resourceChanges': !exists(json, 'resourceChanges') ? undefined : KvintaResourceChangesFromJSON(json['resourceChanges']),
        'queueAction': !exists(json, 'queueAction') ? undefined : KvintaQueueActionFromJSON(json['queueAction']),
        'continued': !exists(json, 'continued') ? undefined : json['continued'],
    };
}

export function KvintaOperationResponseListMessageStatusRecordV10ToJSON(value?: KvintaOperationResponseListMessageStatusRecordV10 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(KvintaMessageStatusRecordV10ToJSON)),
        'status': KvintaOperationStatusToJSON(value.status),
        'error': value.error,
        'message': value.message,
        'executionId': value.executionId,
        'rootExecutionId': value.rootExecutionId,
        'resource': KvintaResourceToJSON(value.resource),
        'duplicate': value.duplicate,
        'resourceChanges': KvintaResourceChangesToJSON(value.resourceChanges),
        'queueAction': KvintaQueueActionToJSON(value.queueAction),
        'continued': value.continued,
    };
}


