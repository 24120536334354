/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaMessageAttributes
 */
export interface KvintaMessageAttributes {
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageAttributes
     */
    sender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageAttributes
     */
    receiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageAttributes
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageAttributes
     */
    triggeringEventId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaMessageAttributes
     */
    triggeringEventTs?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageAttributes
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageAttributes
     */
    workflowId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageAttributes
     */
    destinationSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageAttributes
     */
    retryMessageId?: string | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KvintaMessageAttributes
     */
    attributes?: { [key: string]: object; } | null;
}

export function KvintaMessageAttributesFromJSON(json: any): KvintaMessageAttributes {
    return KvintaMessageAttributesFromJSONTyped(json, false);
}

export function KvintaMessageAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMessageAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
        'receiver': !exists(json, 'receiver') ? undefined : json['receiver'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'triggeringEventId': !exists(json, 'triggeringEventId') ? undefined : json['triggeringEventId'],
        'triggeringEventTs': !exists(json, 'triggeringEventTs') ? undefined : json['triggeringEventTs'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'destinationSystem': !exists(json, 'destinationSystem') ? undefined : json['destinationSystem'],
        'retryMessageId': !exists(json, 'retryMessageId') ? undefined : json['retryMessageId'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function KvintaMessageAttributesToJSON(value?: KvintaMessageAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sender': value.sender,
        'receiver': value.receiver,
        'externalId': value.externalId,
        'triggeringEventId': value.triggeringEventId,
        'triggeringEventTs': value.triggeringEventTs,
        'userId': value.userId,
        'workflowId': value.workflowId,
        'destinationSystem': value.destinationSystem,
        'retryMessageId': value.retryMessageId,
        'attributes': value.attributes,
    };
}


