/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaRetrieveHierarchyFilter
 */
export interface KvintaRetrieveHierarchyFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaRetrieveHierarchyFilter
     */
    gln13?: string | null;
    /**
     * 
     * @type {Array<KvintaEpcisBizStep>}
     * @memberof KvintaRetrieveHierarchyFilter
     */
    triggeringBizSteps?: Array<KvintaEpcisBizStep> | null;
}

export function KvintaRetrieveHierarchyFilterFromJSON(json: any): KvintaRetrieveHierarchyFilter {
    return KvintaRetrieveHierarchyFilterFromJSONTyped(json, false);
}

export function KvintaRetrieveHierarchyFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaRetrieveHierarchyFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gln13': !exists(json, 'gln13') ? undefined : json['gln13'],
        'triggeringBizSteps': !exists(json, 'triggeringBizSteps') ? undefined : (json['triggeringBizSteps'] === null ? null : (json['triggeringBizSteps'] as Array<any>).map(KvintaEpcisBizStepFromJSON)),
    };
}

export function KvintaRetrieveHierarchyFilterToJSON(value?: KvintaRetrieveHierarchyFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gln13': value.gln13,
        'triggeringBizSteps': value.triggeringBizSteps === undefined ? undefined : (value.triggeringBizSteps === null ? null : (value.triggeringBizSteps as Array<any>).map(KvintaEpcisBizStepToJSON)),
    };
}


