/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAutoUnpackingScope,
    KvintaAutoUnpackingScopeFromJSON,
    KvintaAutoUnpackingScopeFromJSONTyped,
    KvintaAutoUnpackingScopeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaAutoUnpackingSettings
 */
export interface KvintaAutoUnpackingSettings {
    /**
     * 
     * @type {KvintaAutoUnpackingScope}
     * @memberof KvintaAutoUnpackingSettings
     */
    scope: KvintaAutoUnpackingScope;
    /**
     * 
     * @type {string}
     * @memberof KvintaAutoUnpackingSettings
     */
    readPointOverride?: string | null;
}

export function KvintaAutoUnpackingSettingsFromJSON(json: any): KvintaAutoUnpackingSettings {
    return KvintaAutoUnpackingSettingsFromJSONTyped(json, false);
}

export function KvintaAutoUnpackingSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAutoUnpackingSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scope': KvintaAutoUnpackingScopeFromJSON(json['scope']),
        'readPointOverride': !exists(json, 'readPointOverride') ? undefined : json['readPointOverride'],
    };
}

export function KvintaAutoUnpackingSettingsToJSON(value?: KvintaAutoUnpackingSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scope': KvintaAutoUnpackingScopeToJSON(value.scope),
        'readPointOverride': value.readPointOverride,
    };
}


