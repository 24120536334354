/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSplitByCustomsNumber,
    KvintaSplitByCustomsNumberFromJSON,
    KvintaSplitByCustomsNumberFromJSONTyped,
    KvintaSplitByCustomsNumberToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOwnerNotificationConfig
 */
export interface KvintaOwnerNotificationConfig {
    /**
     * 
     * @type {KvintaSplitByCustomsNumber}
     * @memberof KvintaOwnerNotificationConfig
     */
    splitByCustomsNumber?: KvintaSplitByCustomsNumber | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaOwnerNotificationConfig
     */
    invoiceRefCountry?: Array<string> | null;
}

export function KvintaOwnerNotificationConfigFromJSON(json: any): KvintaOwnerNotificationConfig {
    return KvintaOwnerNotificationConfigFromJSONTyped(json, false);
}

export function KvintaOwnerNotificationConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOwnerNotificationConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'splitByCustomsNumber': !exists(json, 'splitByCustomsNumber') ? undefined : KvintaSplitByCustomsNumberFromJSON(json['splitByCustomsNumber']),
        'invoiceRefCountry': !exists(json, 'invoiceRefCountry') ? undefined : json['invoiceRefCountry'],
    };
}

export function KvintaOwnerNotificationConfigToJSON(value?: KvintaOwnerNotificationConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'splitByCustomsNumber': KvintaSplitByCustomsNumberToJSON(value.splitByCustomsNumber),
        'invoiceRefCountry': value.invoiceRefCountry,
    };
}


