/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaRetrieveHierarchyFilter,
    KvintaRetrieveHierarchyFilterFromJSON,
    KvintaRetrieveHierarchyFilterFromJSONTyped,
    KvintaRetrieveHierarchyFilterToJSON,
    KvintaWorkflowConfiguration,
    KvintaWorkflowConfigurationFromJSON,
    KvintaWorkflowConfigurationFromJSONTyped,
    KvintaWorkflowConfigurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaRetrieveHierarchyConfig
 */
export interface KvintaRetrieveHierarchyConfig {
    /**
     * 
     * @type {Array<KvintaRetrieveHierarchyFilter>}
     * @memberof KvintaRetrieveHierarchyConfig
     */
    filters?: Array<KvintaRetrieveHierarchyFilter> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaRetrieveHierarchyConfig
     */
    workflowType?: string | null;
    /**
     * 
     * @type {KvintaWorkflowConfiguration}
     * @memberof KvintaRetrieveHierarchyConfig
     */
    workflowOptions?: KvintaWorkflowConfiguration | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaRetrieveHierarchyConfig
     */
    numRows: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaRetrieveHierarchyConfig
     */
    readPoint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaRetrieveHierarchyConfig
     */
    defaultBatchNumber?: string | null;
}

export function KvintaRetrieveHierarchyConfigFromJSON(json: any): KvintaRetrieveHierarchyConfig {
    return KvintaRetrieveHierarchyConfigFromJSONTyped(json, false);
}

export function KvintaRetrieveHierarchyConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaRetrieveHierarchyConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filters': !exists(json, 'filters') ? undefined : (json['filters'] === null ? null : (json['filters'] as Array<any>).map(KvintaRetrieveHierarchyFilterFromJSON)),
        'workflowType': !exists(json, 'workflowType') ? undefined : json['workflowType'],
        'workflowOptions': !exists(json, 'workflowOptions') ? undefined : KvintaWorkflowConfigurationFromJSON(json['workflowOptions']),
        'numRows': json['numRows'],
        'readPoint': !exists(json, 'readPoint') ? undefined : json['readPoint'],
        'defaultBatchNumber': !exists(json, 'defaultBatchNumber') ? undefined : json['defaultBatchNumber'],
    };
}

export function KvintaRetrieveHierarchyConfigToJSON(value?: KvintaRetrieveHierarchyConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filters': value.filters === undefined ? undefined : (value.filters === null ? null : (value.filters as Array<any>).map(KvintaRetrieveHierarchyFilterToJSON)),
        'workflowType': value.workflowType,
        'workflowOptions': KvintaWorkflowConfigurationToJSON(value.workflowOptions),
        'numRows': value.numRows,
        'readPoint': value.readPoint,
        'defaultBatchNumber': value.defaultBatchNumber,
    };
}


