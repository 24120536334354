/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisAction,
    KvintaEpcisActionFromJSON,
    KvintaEpcisActionFromJSONTyped,
    KvintaEpcisActionToJSON,
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
    KvintaEpcisEventType,
    KvintaEpcisEventTypeFromJSON,
    KvintaEpcisEventTypeFromJSONTyped,
    KvintaEpcisEventTypeToJSON,
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisEventRecord
 */
export interface KvintaEpcisEventRecord {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    sha256: string;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaEpcisEventRecord
     */
    eventTime: KvintaTimestamp;
    /**
     * 
     * @type {KvintaEpcisEventType}
     * @memberof KvintaEpcisEventRecord
     */
    eventType: KvintaEpcisEventType;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    eventTimeZoneOffset: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    eventId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisEventRecord
     */
    recordTime?: number | null;
    /**
     * 
     * @type {KvintaEpcisAction}
     * @memberof KvintaEpcisEventRecord
     */
    action: KvintaEpcisAction;
    /**
     * 
     * @type {KvintaEpcisBizStep}
     * @memberof KvintaEpcisEventRecord
     */
    bizStep: KvintaEpcisBizStep;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    readPoint: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    bizLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    disposition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    persistentDisposition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    parentID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    transformationID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    createdByExecutionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    createdByRootExecutionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    documentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    sampleCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisEventRecord
     */
    epcCount: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    prodBatch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KvintaEpcisEventRecord
     */
    bizTransactions?: { [key: string]: string; } | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisEventRecord
     */
    errorDeclarationTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    errorDeclarationReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisEventRecord
     */
    cancelledByDocId?: string | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KvintaEpcisEventRecord
     */
    attributes?: { [key: string]: object; } | null;
}

export function KvintaEpcisEventRecordFromJSON(json: any): KvintaEpcisEventRecord {
    return KvintaEpcisEventRecordFromJSONTyped(json, false);
}

export function KvintaEpcisEventRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisEventRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sha256': json['sha256'],
        'eventTime': KvintaTimestampFromJSON(json['eventTime']),
        'eventType': KvintaEpcisEventTypeFromJSON(json['eventType']),
        'eventTimeZoneOffset': json['eventTimeZoneOffset'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'recordTime': !exists(json, 'recordTime') ? undefined : json['recordTime'],
        'action': KvintaEpcisActionFromJSON(json['action']),
        'bizStep': KvintaEpcisBizStepFromJSON(json['bizStep']),
        'readPoint': json['readPoint'],
        'bizLocation': !exists(json, 'bizLocation') ? undefined : json['bizLocation'],
        'disposition': !exists(json, 'disposition') ? undefined : json['disposition'],
        'persistentDisposition': !exists(json, 'persistentDisposition') ? undefined : json['persistentDisposition'],
        'parentID': !exists(json, 'parentID') ? undefined : json['parentID'],
        'transformationID': !exists(json, 'transformationID') ? undefined : json['transformationID'],
        'createdByExecutionId': !exists(json, 'createdByExecutionId') ? undefined : json['createdByExecutionId'],
        'createdByRootExecutionId': !exists(json, 'createdByRootExecutionId') ? undefined : json['createdByRootExecutionId'],
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'sampleCode': !exists(json, 'sampleCode') ? undefined : json['sampleCode'],
        'epcCount': json['epcCount'],
        'prodBatch': !exists(json, 'prodBatch') ? undefined : json['prodBatch'],
        'expiryDate': !exists(json, 'expiryDate') ? undefined : json['expiryDate'],
        'bizTransactions': !exists(json, 'bizTransactions') ? undefined : json['bizTransactions'],
        'errorDeclarationTime': !exists(json, 'errorDeclarationTime') ? undefined : json['errorDeclarationTime'],
        'errorDeclarationReason': !exists(json, 'errorDeclarationReason') ? undefined : json['errorDeclarationReason'],
        'cancelledByDocId': !exists(json, 'cancelledByDocId') ? undefined : json['cancelledByDocId'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function KvintaEpcisEventRecordToJSON(value?: KvintaEpcisEventRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sha256': value.sha256,
        'eventTime': KvintaTimestampToJSON(value.eventTime),
        'eventType': KvintaEpcisEventTypeToJSON(value.eventType),
        'eventTimeZoneOffset': value.eventTimeZoneOffset,
        'eventId': value.eventId,
        'recordTime': value.recordTime,
        'action': KvintaEpcisActionToJSON(value.action),
        'bizStep': KvintaEpcisBizStepToJSON(value.bizStep),
        'readPoint': value.readPoint,
        'bizLocation': value.bizLocation,
        'disposition': value.disposition,
        'persistentDisposition': value.persistentDisposition,
        'parentID': value.parentID,
        'transformationID': value.transformationID,
        'createdByExecutionId': value.createdByExecutionId,
        'createdByRootExecutionId': value.createdByRootExecutionId,
        'documentId': value.documentId,
        'tenantId': value.tenantId,
        'userId': value.userId,
        'sampleCode': value.sampleCode,
        'epcCount': value.epcCount,
        'prodBatch': value.prodBatch,
        'expiryDate': value.expiryDate,
        'bizTransactions': value.bizTransactions,
        'errorDeclarationTime': value.errorDeclarationTime,
        'errorDeclarationReason': value.errorDeclarationReason,
        'cancelledByDocId': value.cancelledByDocId,
        'attributes': value.attributes,
    };
}


