/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaConsistencyCheckConfig
 */
export interface KvintaConsistencyCheckConfig {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaConsistencyCheckConfig
     */
    enabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaConsistencyCheckConfig
     */
    shipmentExcludedGln?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaConsistencyCheckConfig
     */
    useV2Implementation: boolean;
}

export function KvintaConsistencyCheckConfigFromJSON(json: any): KvintaConsistencyCheckConfig {
    return KvintaConsistencyCheckConfigFromJSONTyped(json, false);
}

export function KvintaConsistencyCheckConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaConsistencyCheckConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'shipmentExcludedGln': !exists(json, 'shipmentExcludedGln') ? undefined : json['shipmentExcludedGln'],
        'useV2Implementation': json['useV2Implementation'],
    };
}

export function KvintaConsistencyCheckConfigToJSON(value?: KvintaConsistencyCheckConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'shipmentExcludedGln': value.shipmentExcludedGln,
        'useV2Implementation': value.useV2Implementation,
    };
}


