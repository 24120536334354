import { action, computed, makeObservable, observable } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as ECaptureStoreApi,
  KvintaEpcisEvent,
  KvintaEpcisEventRecord,
  KvintaOperationStatus,
  KvintaEpcisBizStep,
} from 'kvinta/apis/kvinta-epcis-capture';
import { DefaultApi as EpcisStoreApi } from 'kvinta/apis/kvinta-epcis-store';
import { KvintaSortDirection, KvintaSortExpressions } from 'kvinta/apis/kvinta-masterdata';
import { NotificationManager } from 'kvinta/modules/main';
import { EFilterType, IFilter } from '../../../components/FilterBar/FilterBar';

interface ExtKvintaEpcisEventRecord extends KvintaEpcisEventRecord {
  recordTimeExt: string;
  eventTimeExt: string;
  bizTransaction: string;
}

export const bizStepOption: { [key in KvintaEpcisBizStep]: string } = {
  [KvintaEpcisBizStep.Commissioning]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Commissioning.toLowerCase()}`,
  [KvintaEpcisBizStep.Packing]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Packing.toLowerCase()}`,
  [KvintaEpcisBizStep.Shipping]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Shipping.toLowerCase()}`,
  [KvintaEpcisBizStep.Receiving]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Receiving.toLowerCase()}`,
  [KvintaEpcisBizStep.Inspecting]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Inspecting.toLowerCase()}`,
  [KvintaEpcisBizStep.Decommissioning]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Decommissioning.toLowerCase()}`,
  [KvintaEpcisBizStep.Unpacking]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Unpacking.toLowerCase()}`,
  [KvintaEpcisBizStep.Verification]: `urn:saga:cbv:bizstep:${KvintaEpcisBizStep.Verification.toLowerCase()}`,
  [KvintaEpcisBizStep.Destroying]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Destroying.toLowerCase()}`,

  //added new options
  [KvintaEpcisBizStep.Assembling]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Assembling.toLowerCase()}`,
  [KvintaEpcisBizStep.Associating]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Associating.toLowerCase()}`,
  [KvintaEpcisBizStep.CycleCounting]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.CycleCounting.toLowerCase()}`,
  [KvintaEpcisBizStep.Disassembling]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Disassembling.toLowerCase()}`,
  [KvintaEpcisBizStep.Disposing]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Disposing.toLowerCase()}`,
  [KvintaEpcisBizStep.Expiration]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Expiration.toLowerCase()}`,
  [KvintaEpcisBizStep.Installing]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Installing.toLowerCase()}`,
  [KvintaEpcisBizStep.Loading]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Loading.toLowerCase()}`,
  [KvintaEpcisBizStep.Production]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Production.toLowerCase()}`,
  [KvintaEpcisBizStep.Removing]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Removing.toLowerCase()}`,
  [KvintaEpcisBizStep.Staging]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Staging.toLowerCase()}`,
  [KvintaEpcisBizStep.Unloading]: `urn:epcglobal:cbv:bizstep:${KvintaEpcisBizStep.Unloading.toLowerCase()}`,
};
const bizStepOptions = Object.values(KvintaEpcisBizStep).map((value) => ({
  key: bizStepOption[value],
  label: value.toLowerCase().replace(/^[^a-zа-яё]*([a-zа-яё])/i, function (m) {
    return m.toUpperCase();
  }),
}));

export class EpcisEventsStore {
  private _config: TAppOptionsConfig;
  private _epcisCaptureApi: ECaptureStoreApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: ExtKvintaEpcisEventRecord[];
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;

  filter: IFilter;

  currentEvent?: KvintaEpcisEvent;
  currentExecutionId?: string;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    epcisCaptureApi: ECaptureStoreApi,
    epcisStoreApi: EpcisStoreApi,
  ) {
    makeObservable(this, {
      fetchPage: action.bound,
      loadEvent: action.bound,

      isLoading: observable,
      listData: observable,
      page: observable,
      pageSize: observable,
      filter: observable,

      jsonPayload: computed,
      currentEvent: observable,
      currentExecutionId: observable,
    });

    this._config = config;
    this._epcisCaptureApi = epcisCaptureApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;

    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'recordTime',
          ignoreCase: false,
        },
      ],
    };
    this.filter = new IFilter(
      [
        {
          type: EFilterType.TEXT_FILTER,
          id: 'id',
          label: 'epcis-event.id',
          isActive: true,
          value: '',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'bizLocation',
          label: 'epcis-event.bizlocation',
          isActive: false,
          value: '',
        },
        {
          type: EFilterType.SELECT_FILTER,
          id: 'bizStep',
          options: bizStepOptions,
          label: 'epcis-event.bizstep',
          isActive: false,
          value: 'default',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'docNumber',
          label: 'epcis-event.docNumber',
          isActive: false,
          value: '',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'prodBatch',
          label: 'epcis-event.prodBatch',
          isActive: false,
          value: '',
        },
      ],
      this.doFilter,
    );
  }

  doFilter = async () => {
    this.isLoading = true;
    this.page = 0;

    this.fetchData();
  };

  async fetchPage(page: number) {
    this.isLoading = true;
    this.page = page;

    this.fetchData();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    this.isLoading = true;
    const dir = getDirection(orderDirection);
    const field = getField(orderBy);
    this.currentSort = {
      expressions: [
        {
          direction: dir,
          property: field,
          ignoreCase: false,
        },
      ],
    };
    this.fetchData();
  }

  async fetchData() {
    /*if (
      this.filter.visibleFilters.length === 1 &&
      this.filter.visibleFilters[0].id === 'id' &&
      (this.filter.visibleFilters[0].value as string).trim() !== ''
    ) {
      return this.fetchDataById(this.filter.visibleFilters[0].value as string);
    }*/

    const filters = this.filter.visibleFilters.reduce((acc, filter) => {
      if (filter.type === EFilterType.SELECT_FILTER && filter.value !== 'default') {
        acc[filter.id] = filter.value;
      } else if (
        filter.type !== EFilterType.SELECT_FILTER &&
        filter.type !== EFilterType.MULITPLE_SELECT_FILTER &&
        (filter.value as string).trim() !== ''
      ) {
        acc[filter.id] = filter.value;
      }
      return acc;
    }, {});

    this._epcisCaptureApi
      .listEpcisEvents({
        kvintaOperationRequestListEpcisEventsRequest: {
          input: {
            paging: { page: this.page, size: this.pageSize, sort: this.currentSort },
            filters,
          },
        },
      })
      .then((result) => {
        if (result.status !== KvintaOperationStatus.Ok) {
          this.listData = [];
          this._notificationManager.sendError(result.error);
        } else {
          this.listData = (result.data.list || []).map(transformEpcisRecord);
          this.totalCount = result.data.total;
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async fetchDataById(id: string) {
    this._epcisCaptureApi
      .readEpcisEventById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        this.isLoading = false;
        if (result.status === KvintaOperationStatus.Error) {
          this.listData = [];
        } else {
          this.listData = [transformEpcisRecord(result.data)];
          this.totalCount = 1;
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  }

  async loadEvent(id: string) {
    this.currentEvent = undefined;
    this.currentExecutionId = undefined;
    this._epcisCaptureApi
      .readEpcisEventById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Ok) {
          this.currentEvent = result.data;
          this.currentExecutionId = result.executionId;
        } else {
          this._notificationManager.sendError(result.error);
        }
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      });
  }

  get jsonPayload() {
    if (this.currentEvent) {
      return JSON.stringify(this.currentEvent, null, 2);
    } else {
      return 'No data available'; // TODO: Maybe translate
    }
  }
}

function shortenBizTransaction(longBizTransaction: string) {
  //if there are ':'s in the string, only show the part after the last ':' otherwise show entire string
  const split = longBizTransaction.split(':');
  return split[split.length - 1];
}

function getBizTransaction(bizTransactions: { [key: string]: string }) {
  if (!bizTransactions) {
    return '';
  }

  if (Object.values(bizTransactions).length === 1) {
    return shortenBizTransaction(Object.values(bizTransactions)[0]);
  } else if (Object.values(bizTransactions).length > 1) {
    if (bizTransactions['urn:epcglobal:cbv:btt:recadv'] !== undefined) {
      return shortenBizTransaction(bizTransactions['urn:epcglobal:cbv:btt:recadv']);
    }
    if (bizTransactions['urn:epcglobal:cbv:btt:desadv'] !== undefined) {
      return shortenBizTransaction(bizTransactions['urn:epcglobal:cbv:btt:desadv']);
    }
    return shortenBizTransaction(Object.values(bizTransactions)[0]);
  } else {
    return '';
  }
}

function transformEpcisRecord(eventRecord: KvintaEpcisEventRecord | KvintaEpcisEvent) {
  return {
    ...eventRecord,
    eventTimeExt: new Date(eventRecord.eventTime.epochMillis).toISOString(),
    recordTimeExt: new Date(eventRecord.recordTime).toISOString(),
    bizTransaction: getBizTransaction((eventRecord as KvintaEpcisEventRecord).bizTransactions),
  } as ExtKvintaEpcisEventRecord;
}

export const EPCIS_EVENTS_STORE_ID = 'epcisEventsStore';

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'eventTime';
    case 1:
      return 'recordTime';
    case 2:
      return 'action';
    case 3:
      return 'bizStep';
    case 4:
      return 'readPoint';
    case 5:
      return 'bizLocation';
    case 6:
      return 'disposition';
    case 7:
      return 'persistentDisposition';
    case 8:
      return 'userId';
    case 9:
      return 'epcCount';
    case 10:
      return 'bizTransaction';
    case 11:
      return 'prodBatch';
    default:
      return 'eventTime';
  }
}
