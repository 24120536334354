/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAutoUnpackingConfigUnit,
    KvintaAutoUnpackingConfigUnitFromJSON,
    KvintaAutoUnpackingConfigUnitFromJSONTyped,
    KvintaAutoUnpackingConfigUnitToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaAutoUnpackingConfig
 */
export interface KvintaAutoUnpackingConfig {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaAutoUnpackingConfig
     */
    enabled: boolean;
    /**
     * 
     * @type {KvintaAutoUnpackingConfigUnit}
     * @memberof KvintaAutoUnpackingConfig
     */
    sscc?: KvintaAutoUnpackingConfigUnit | null;
    /**
     * 
     * @type {KvintaAutoUnpackingConfigUnit}
     * @memberof KvintaAutoUnpackingConfig
     */
    sgtin?: KvintaAutoUnpackingConfigUnit | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaAutoUnpackingConfig
     */
    eventTimeOffsetSeconds?: number | null;
}

export function KvintaAutoUnpackingConfigFromJSON(json: any): KvintaAutoUnpackingConfig {
    return KvintaAutoUnpackingConfigFromJSONTyped(json, false);
}

export function KvintaAutoUnpackingConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAutoUnpackingConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'sscc': !exists(json, 'sscc') ? undefined : KvintaAutoUnpackingConfigUnitFromJSON(json['sscc']),
        'sgtin': !exists(json, 'sgtin') ? undefined : KvintaAutoUnpackingConfigUnitFromJSON(json['sgtin']),
        'eventTimeOffsetSeconds': !exists(json, 'eventTimeOffsetSeconds') ? undefined : json['eventTimeOffsetSeconds'],
    };
}

export function KvintaAutoUnpackingConfigToJSON(value?: KvintaAutoUnpackingConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'sscc': KvintaAutoUnpackingConfigUnitToJSON(value.sscc),
        'sgtin': KvintaAutoUnpackingConfigUnitToJSON(value.sgtin),
        'eventTimeOffsetSeconds': value.eventTimeOffsetSeconds,
    };
}


