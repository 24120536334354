/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAutoUnpackingSettings,
    KvintaAutoUnpackingSettingsFromJSON,
    KvintaAutoUnpackingSettingsFromJSONTyped,
    KvintaAutoUnpackingSettingsToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaAutoUnpackingConfigUnit
 */
export interface KvintaAutoUnpackingConfigUnit {
    /**
     * 
     * @type {KvintaAutoUnpackingSettings}
     * @memberof KvintaAutoUnpackingConfigUnit
     */
    settings: KvintaAutoUnpackingSettings;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof KvintaAutoUnpackingConfigUnit
     */
    conditions?: { [key: string]: Array<string>; } | null;
}

export function KvintaAutoUnpackingConfigUnitFromJSON(json: any): KvintaAutoUnpackingConfigUnit {
    return KvintaAutoUnpackingConfigUnitFromJSONTyped(json, false);
}

export function KvintaAutoUnpackingConfigUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAutoUnpackingConfigUnit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settings': KvintaAutoUnpackingSettingsFromJSON(json['settings']),
        'conditions': !exists(json, 'conditions') ? undefined : json['conditions'],
    };
}

export function KvintaAutoUnpackingConfigUnitToJSON(value?: KvintaAutoUnpackingConfigUnit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings': KvintaAutoUnpackingSettingsToJSON(value.settings),
        'conditions': value.conditions,
    };
}


