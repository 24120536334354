/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaBizTransactionInEvent,
    KvintaBizTransactionInEventFromJSON,
    KvintaBizTransactionInEventFromJSONTyped,
    KvintaBizTransactionInEventToJSON,
    KvintaCaptureErrorDeclaration,
    KvintaCaptureErrorDeclarationFromJSON,
    KvintaCaptureErrorDeclarationFromJSONTyped,
    KvintaCaptureErrorDeclarationToJSON,
    KvintaCaptureSensorElement,
    KvintaCaptureSensorElementFromJSON,
    KvintaCaptureSensorElementFromJSONTyped,
    KvintaCaptureSensorElementToJSON,
    KvintaDestinationElement,
    KvintaDestinationElementFromJSON,
    KvintaDestinationElementFromJSONTyped,
    KvintaDestinationElementToJSON,
    KvintaEpcisAction,
    KvintaEpcisActionFromJSON,
    KvintaEpcisActionFromJSONTyped,
    KvintaEpcisActionToJSON,
    KvintaEpcisEventType,
    KvintaEpcisEventTypeFromJSON,
    KvintaEpcisEventTypeFromJSONTyped,
    KvintaEpcisEventTypeToJSON,
    KvintaKvintaEventExtension,
    KvintaKvintaEventExtensionFromJSON,
    KvintaKvintaEventExtensionFromJSONTyped,
    KvintaKvintaEventExtensionToJSON,
    KvintaQuantityElement,
    KvintaQuantityElementFromJSON,
    KvintaQuantityElementFromJSONTyped,
    KvintaQuantityElementToJSON,
    KvintaSourceElement,
    KvintaSourceElementFromJSON,
    KvintaSourceElementFromJSONTyped,
    KvintaSourceElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCaptureEpcisEvent
 */
export interface KvintaCaptureEpcisEvent {
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    id?: string | null;
    /**
     * 
     * @type {KvintaEpcisEventType}
     * @memberof KvintaCaptureEpcisEvent
     */
    isA: KvintaEpcisEventType;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    eventID?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof KvintaCaptureEpcisEvent
     */
    eventTime: Date;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    eventTimeZoneOffset: string;
    /**
     * 
     * @type {Date}
     * @memberof KvintaCaptureEpcisEvent
     */
    recordTime?: Date | null;
    /**
     * 
     * @type {KvintaEpcisAction}
     * @memberof KvintaCaptureEpcisEvent
     */
    action: KvintaEpcisAction;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    bizStep: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    readPoint: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    bizLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    disposition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    persistentDisposition?: string | null;
    /**
     * 
     * @type {Array<KvintaBizTransactionInEvent>}
     * @memberof KvintaCaptureEpcisEvent
     */
    bizTransactionList?: Array<KvintaBizTransactionInEvent> | null;
    /**
     * 
     * @type {Array<KvintaSourceElement>}
     * @memberof KvintaCaptureEpcisEvent
     */
    sourceList?: Array<KvintaSourceElement> | null;
    /**
     * 
     * @type {Array<KvintaDestinationElement>}
     * @memberof KvintaCaptureEpcisEvent
     */
    destinationList?: Array<KvintaDestinationElement> | null;
    /**
     * 
     * @type {Array<KvintaCaptureSensorElement>}
     * @memberof KvintaCaptureEpcisEvent
     */
    sensorElementList?: Array<KvintaCaptureSensorElement> | null;
    /**
     * 
     * @type {KvintaCaptureErrorDeclaration}
     * @memberof KvintaCaptureEpcisEvent
     */
    errorDeclaration?: KvintaCaptureErrorDeclaration | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    parentID?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaCaptureEpcisEvent
     */
    childEPCs?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaCaptureEpcisEvent
     */
    epcList?: Array<string> | null;
    /**
     * 
     * @type {Array<KvintaQuantityElement>}
     * @memberof KvintaCaptureEpcisEvent
     */
    quantityList?: Array<KvintaQuantityElement> | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KvintaCaptureEpcisEvent
     */
    ilmd?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<KvintaQuantityElement>}
     * @memberof KvintaCaptureEpcisEvent
     */
    childQuantityList?: Array<KvintaQuantityElement> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaCaptureEpcisEvent
     */
    inputEPCList?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaCaptureEpcisEvent
     */
    outputEPCList?: Array<string> | null;
    /**
     * 
     * @type {Array<KvintaQuantityElement>}
     * @memberof KvintaCaptureEpcisEvent
     */
    inputQuantityList?: Array<KvintaQuantityElement> | null;
    /**
     * 
     * @type {Array<KvintaQuantityElement>}
     * @memberof KvintaCaptureEpcisEvent
     */
    outputQuantityList?: Array<KvintaQuantityElement> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisEvent
     */
    transformationID?: string | null;
    /**
     * 
     * @type {KvintaKvintaEventExtension}
     * @memberof KvintaCaptureEpcisEvent
     */
    kvintaEventExtension?: KvintaKvintaEventExtension | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaCaptureEpcisEvent
     */
    autoEvent?: boolean | null;
}

export function KvintaCaptureEpcisEventFromJSON(json: any): KvintaCaptureEpcisEvent {
    return KvintaCaptureEpcisEventFromJSONTyped(json, false);
}

export function KvintaCaptureEpcisEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCaptureEpcisEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isA': KvintaEpcisEventTypeFromJSON(json['isA']),
        'eventID': !exists(json, 'eventID') ? undefined : json['eventID'],
        'eventTime': (new Date(json['eventTime'])),
        'eventTimeZoneOffset': json['eventTimeZoneOffset'],
        'recordTime': !exists(json, 'recordTime') ? undefined : (json['recordTime'] === null ? null : new Date(json['recordTime'])),
        'action': KvintaEpcisActionFromJSON(json['action']),
        'bizStep': json['bizStep'],
        'readPoint': json['readPoint'],
        'bizLocation': !exists(json, 'bizLocation') ? undefined : json['bizLocation'],
        'disposition': !exists(json, 'disposition') ? undefined : json['disposition'],
        'persistentDisposition': !exists(json, 'persistentDisposition') ? undefined : json['persistentDisposition'],
        'bizTransactionList': !exists(json, 'bizTransactionList') ? undefined : (json['bizTransactionList'] === null ? null : (json['bizTransactionList'] as Array<any>).map(KvintaBizTransactionInEventFromJSON)),
        'sourceList': !exists(json, 'sourceList') ? undefined : (json['sourceList'] === null ? null : (json['sourceList'] as Array<any>).map(KvintaSourceElementFromJSON)),
        'destinationList': !exists(json, 'destinationList') ? undefined : (json['destinationList'] === null ? null : (json['destinationList'] as Array<any>).map(KvintaDestinationElementFromJSON)),
        'sensorElementList': !exists(json, 'sensorElementList') ? undefined : (json['sensorElementList'] === null ? null : (json['sensorElementList'] as Array<any>).map(KvintaCaptureSensorElementFromJSON)),
        'errorDeclaration': !exists(json, 'errorDeclaration') ? undefined : KvintaCaptureErrorDeclarationFromJSON(json['errorDeclaration']),
        'parentID': !exists(json, 'parentID') ? undefined : json['parentID'],
        'childEPCs': !exists(json, 'childEPCs') ? undefined : json['childEPCs'],
        'epcList': !exists(json, 'epcList') ? undefined : json['epcList'],
        'quantityList': !exists(json, 'quantityList') ? undefined : (json['quantityList'] === null ? null : (json['quantityList'] as Array<any>).map(KvintaQuantityElementFromJSON)),
        'ilmd': !exists(json, 'ilmd') ? undefined : json['ilmd'],
        'childQuantityList': !exists(json, 'childQuantityList') ? undefined : (json['childQuantityList'] === null ? null : (json['childQuantityList'] as Array<any>).map(KvintaQuantityElementFromJSON)),
        'inputEPCList': !exists(json, 'inputEPCList') ? undefined : json['inputEPCList'],
        'outputEPCList': !exists(json, 'outputEPCList') ? undefined : json['outputEPCList'],
        'inputQuantityList': !exists(json, 'inputQuantityList') ? undefined : (json['inputQuantityList'] === null ? null : (json['inputQuantityList'] as Array<any>).map(KvintaQuantityElementFromJSON)),
        'outputQuantityList': !exists(json, 'outputQuantityList') ? undefined : (json['outputQuantityList'] === null ? null : (json['outputQuantityList'] as Array<any>).map(KvintaQuantityElementFromJSON)),
        'transformationID': !exists(json, 'transformationID') ? undefined : json['transformationID'],
        'kvintaEventExtension': !exists(json, 'kvintaEventExtension') ? undefined : KvintaKvintaEventExtensionFromJSON(json['kvintaEventExtension']),
        'autoEvent': !exists(json, 'autoEvent') ? undefined : json['autoEvent'],
    };
}

export function KvintaCaptureEpcisEventToJSON(value?: KvintaCaptureEpcisEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isA': KvintaEpcisEventTypeToJSON(value.isA),
        'eventID': value.eventID,
        'eventTime': (value.eventTime.toISOString()),
        'eventTimeZoneOffset': value.eventTimeZoneOffset,
        'recordTime': value.recordTime === undefined ? undefined : (value.recordTime === null ? null : value.recordTime.toISOString()),
        'action': KvintaEpcisActionToJSON(value.action),
        'bizStep': value.bizStep,
        'readPoint': value.readPoint,
        'bizLocation': value.bizLocation,
        'disposition': value.disposition,
        'persistentDisposition': value.persistentDisposition,
        'bizTransactionList': value.bizTransactionList === undefined ? undefined : (value.bizTransactionList === null ? null : (value.bizTransactionList as Array<any>).map(KvintaBizTransactionInEventToJSON)),
        'sourceList': value.sourceList === undefined ? undefined : (value.sourceList === null ? null : (value.sourceList as Array<any>).map(KvintaSourceElementToJSON)),
        'destinationList': value.destinationList === undefined ? undefined : (value.destinationList === null ? null : (value.destinationList as Array<any>).map(KvintaDestinationElementToJSON)),
        'sensorElementList': value.sensorElementList === undefined ? undefined : (value.sensorElementList === null ? null : (value.sensorElementList as Array<any>).map(KvintaCaptureSensorElementToJSON)),
        'errorDeclaration': KvintaCaptureErrorDeclarationToJSON(value.errorDeclaration),
        'parentID': value.parentID,
        'childEPCs': value.childEPCs,
        'epcList': value.epcList,
        'quantityList': value.quantityList === undefined ? undefined : (value.quantityList === null ? null : (value.quantityList as Array<any>).map(KvintaQuantityElementToJSON)),
        'ilmd': value.ilmd,
        'childQuantityList': value.childQuantityList === undefined ? undefined : (value.childQuantityList === null ? null : (value.childQuantityList as Array<any>).map(KvintaQuantityElementToJSON)),
        'inputEPCList': value.inputEPCList,
        'outputEPCList': value.outputEPCList,
        'inputQuantityList': value.inputQuantityList === undefined ? undefined : (value.inputQuantityList === null ? null : (value.inputQuantityList as Array<any>).map(KvintaQuantityElementToJSON)),
        'outputQuantityList': value.outputQuantityList === undefined ? undefined : (value.outputQuantityList === null ? null : (value.outputQuantityList as Array<any>).map(KvintaQuantityElementToJSON)),
        'transformationID': value.transformationID,
        'kvintaEventExtension': KvintaKvintaEventExtensionToJSON(value.kvintaEventExtension),
        'autoEvent': value.autoEvent,
    };
}


