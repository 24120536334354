/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaAlarmRecord,
    KvintaAlarmRecordFromJSON,
    KvintaAlarmRecordToJSON,
    KvintaCancelEpcisDocumentRequest,
    KvintaCancelEpcisDocumentRequestFromJSON,
    KvintaCancelEpcisDocumentRequestToJSON,
    KvintaComputeShipmentContentRequest,
    KvintaComputeShipmentContentRequestFromJSON,
    KvintaComputeShipmentContentRequestToJSON,
    KvintaConsistencyCheckResult,
    KvintaConsistencyCheckResultFromJSON,
    KvintaConsistencyCheckResultToJSON,
    KvintaDeleteObjectResponseStatus,
    KvintaDeleteObjectResponseStatusFromJSON,
    KvintaDeleteObjectResponseStatusToJSON,
    KvintaDeliveryCheckResult,
    KvintaDeliveryCheckResultFromJSON,
    KvintaDeliveryCheckResultToJSON,
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyToJSON,
    KvintaEpcisDocument,
    KvintaEpcisDocumentFromJSON,
    KvintaEpcisDocumentToJSON,
    KvintaEpcs,
    KvintaEpcsFromJSON,
    KvintaEpcsToJSON,
    KvintaEpcsContent,
    KvintaEpcsContentFromJSON,
    KvintaEpcsContentToJSON,
    KvintaErrorWebResponse,
    KvintaErrorWebResponseFromJSON,
    KvintaErrorWebResponseToJSON,
    KvintaGetEpcByBizTransactionRequest,
    KvintaGetEpcByBizTransactionRequestFromJSON,
    KvintaGetEpcByBizTransactionRequestToJSON,
    KvintaGetEpcByBizTransactionResponse,
    KvintaGetEpcByBizTransactionResponseFromJSON,
    KvintaGetEpcByBizTransactionResponseToJSON,
    KvintaGetEpcContentSummaryRequest,
    KvintaGetEpcContentSummaryRequestFromJSON,
    KvintaGetEpcContentSummaryRequestToJSON,
    KvintaGetEpcContentSummaryResponse,
    KvintaGetEpcContentSummaryResponseFromJSON,
    KvintaGetEpcContentSummaryResponseToJSON,
    KvintaGetEpcDirectChildrenRequest,
    KvintaGetEpcDirectChildrenRequestFromJSON,
    KvintaGetEpcDirectChildrenRequestToJSON,
    KvintaGetEpcDirectChildrenResponse,
    KvintaGetEpcDirectChildrenResponseFromJSON,
    KvintaGetEpcDirectChildrenResponseToJSON,
    KvintaInlineObject2,
    KvintaInlineObject2FromJSON,
    KvintaInlineObject2ToJSON,
    KvintaInlineObject3,
    KvintaInlineObject3FromJSON,
    KvintaInlineObject3ToJSON,
    KvintaMessageKey,
    KvintaMessageKeyFromJSON,
    KvintaMessageKeyToJSON,
    KvintaMessageRecord,
    KvintaMessageRecordFromJSON,
    KvintaMessageRecordToJSON,
    KvintaOperationRequestCaptureEpcisDocumentRequest,
    KvintaOperationRequestCaptureEpcisDocumentRequestFromJSON,
    KvintaOperationRequestCaptureEpcisDocumentRequestToJSON,
    KvintaOperationRequestGetEpcEventListRequest,
    KvintaOperationRequestGetEpcEventListRequestFromJSON,
    KvintaOperationRequestGetEpcEventListRequestToJSON,
    KvintaOperationRequestGetLastStateRequest,
    KvintaOperationRequestGetLastStateRequestFromJSON,
    KvintaOperationRequestGetLastStateRequestToJSON,
    KvintaOperationRequestGetRelativesRequest,
    KvintaOperationRequestGetRelativesRequestFromJSON,
    KvintaOperationRequestGetRelativesRequestToJSON,
    KvintaOperationRequestGetRelevantChangesRequest,
    KvintaOperationRequestGetRelevantChangesRequestFromJSON,
    KvintaOperationRequestGetRelevantChangesRequestToJSON,
    KvintaOperationRequestListEpcisDocumentsRequest,
    KvintaOperationRequestListEpcisDocumentsRequestFromJSON,
    KvintaOperationRequestListEpcisDocumentsRequestToJSON,
    KvintaOperationRequestListEpcisEventsRequest,
    KvintaOperationRequestListEpcisEventsRequestFromJSON,
    KvintaOperationRequestListEpcisEventsRequestToJSON,
    KvintaOperationRequestString,
    KvintaOperationRequestStringFromJSON,
    KvintaOperationRequestStringToJSON,
    KvintaOperationResponseBoolean,
    KvintaOperationResponseBooleanFromJSON,
    KvintaOperationResponseBooleanToJSON,
    KvintaOperationResponseEpcEvent,
    KvintaOperationResponseEpcEventFromJSON,
    KvintaOperationResponseEpcEventToJSON,
    KvintaOperationResponseEpcisDocument,
    KvintaOperationResponseEpcisDocumentFromJSON,
    KvintaOperationResponseEpcisDocumentToJSON,
    KvintaOperationResponseEpcisDocumentRecord,
    KvintaOperationResponseEpcisDocumentRecordFromJSON,
    KvintaOperationResponseEpcisDocumentRecordToJSON,
    KvintaOperationResponseEpcisEvent,
    KvintaOperationResponseEpcisEventFromJSON,
    KvintaOperationResponseEpcisEventToJSON,
    KvintaOperationResponseEpcisEventRecord,
    KvintaOperationResponseEpcisEventRecordFromJSON,
    KvintaOperationResponseEpcisEventRecordToJSON,
    KvintaOperationResponseGetListResultEpcisDocumentRecord,
    KvintaOperationResponseGetListResultEpcisDocumentRecordFromJSON,
    KvintaOperationResponseGetListResultEpcisDocumentRecordToJSON,
    KvintaOperationResponseGetListResultEpcisEventRecord,
    KvintaOperationResponseGetListResultEpcisEventRecordFromJSON,
    KvintaOperationResponseGetListResultEpcisEventRecordToJSON,
    KvintaOperationResponseGetRelevantChangesLocationsResponse,
    KvintaOperationResponseGetRelevantChangesLocationsResponseFromJSON,
    KvintaOperationResponseGetRelevantChangesLocationsResponseToJSON,
    KvintaOperationResponseHierarchyEvents,
    KvintaOperationResponseHierarchyEventsFromJSON,
    KvintaOperationResponseHierarchyEventsToJSON,
    KvintaOperationResponseListEpcEventsResponse,
    KvintaOperationResponseListEpcEventsResponseFromJSON,
    KvintaOperationResponseListEpcEventsResponseToJSON,
    KvintaOperationResponseListEpcisEventRecord,
    KvintaOperationResponseListEpcisEventRecordFromJSON,
    KvintaOperationResponseListEpcisEventRecordToJSON,
    KvintaOperationResponseListLastState,
    KvintaOperationResponseListLastStateFromJSON,
    KvintaOperationResponseListLastStateToJSON,
    KvintaOperationResponseListLong,
    KvintaOperationResponseListLongFromJSON,
    KvintaOperationResponseListLongToJSON,
    KvintaOperationResponseListMessageStatusRecordV10,
    KvintaOperationResponseListMessageStatusRecordV10FromJSON,
    KvintaOperationResponseListMessageStatusRecordV10ToJSON,
    KvintaOperationResponseListString,
    KvintaOperationResponseListStringFromJSON,
    KvintaOperationResponseListStringToJSON,
    KvintaOperationResponseLocation,
    KvintaOperationResponseLocationFromJSON,
    KvintaOperationResponseLocationToJSON,
    KvintaOperationResponseMessageStatusRecordV10,
    KvintaOperationResponseMessageStatusRecordV10FromJSON,
    KvintaOperationResponseMessageStatusRecordV10ToJSON,
    KvintaOperationResponseString,
    KvintaOperationResponseStringFromJSON,
    KvintaOperationResponseStringToJSON,
    KvintaQueryRequestMessageRecordFilter,
    KvintaQueryRequestMessageRecordFilterFromJSON,
    KvintaQueryRequestMessageRecordFilterToJSON,
    KvintaQueryResultMessageRecord,
    KvintaQueryResultMessageRecordFromJSON,
    KvintaQueryResultMessageRecordToJSON,
    KvintaReadObjectResponse,
    KvintaReadObjectResponseFromJSON,
    KvintaReadObjectResponseToJSON,
    KvintaWriteObjectRequest,
    KvintaWriteObjectRequestFromJSON,
    KvintaWriteObjectRequestToJSON,
    KvintaWriteObjectResponse,
    KvintaWriteObjectResponseFromJSON,
    KvintaWriteObjectResponseToJSON,
} from '../models';

export interface CanCancelEpcisDocumentRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface CancelEpcisMessageRequest {
    kvintaCancelEpcisDocumentRequest: KvintaCancelEpcisDocumentRequest;
}

export interface CaptureEpcisDocumentRequest {
    kvintaOperationRequestCaptureEpcisDocumentRequest: KvintaOperationRequestCaptureEpcisDocumentRequest;
}

export interface CaptureXmlEpcisDocumentRequest {
    body: string;
}

export interface CheckConsistencyByDeliveryRequest {
    kvintaDocumentKey: KvintaDocumentKey;
}

export interface CheckConsistencyByDeliveryV2Request {
    kvintaDocumentKey: KvintaDocumentKey;
}

export interface CheckConsistencyByShipmentRequest {
    body: string;
}

export interface ComputeAndStoreSsccContentRequest {
    kvintaEpcs: KvintaEpcs;
}

export interface ComputeShipmentContentRequest {
    kvintaComputeShipmentContentRequest: KvintaComputeShipmentContentRequest;
}

export interface CreateOwnerNotificationRequest {
    body: string;
}

export interface DeleteObjectRequest {
    id: string;
    objtype: string;
}

export interface DeleteObjectsRequest {
    kvintaInlineObject3: KvintaInlineObject3;
}

export interface FindEpcisEventRecordsByParentIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface FindFirstGtinOfEpcisEventRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface FindFirstGtinOfEpcisMessageRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface GetEpcByBizTransactionRequest {
    kvintaGetEpcByBizTransactionRequest: KvintaGetEpcByBizTransactionRequest;
}

export interface GetEpcContentSummaryRequest {
    kvintaGetEpcContentSummaryRequest: KvintaGetEpcContentSummaryRequest;
}

export interface GetEpcDirectChildrenRequest {
    kvintaGetEpcDirectChildrenRequest: KvintaGetEpcDirectChildrenRequest;
}

export interface GetLastDeliveryAlarmRecordRequest {
    body: string;
}

export interface GetMessageRecordStatusHistoryRequest {
    kvintaMessageKey: KvintaMessageKey;
}

export interface GetNestingLevelsRequest {
    kvintaEpcs: KvintaEpcs;
}

export interface GetParentsRequest {
    kvintaEpcs: KvintaEpcs;
}

export interface IgnoreFailedMessageRequest {
    kvintaMessageKey: KvintaMessageKey;
}

export interface ListEpcEventsRequest {
    kvintaOperationRequestGetEpcEventListRequest: KvintaOperationRequestGetEpcEventListRequest;
}

export interface ListEpcisDocumentsRequest {
    kvintaOperationRequestListEpcisDocumentsRequest: KvintaOperationRequestListEpcisDocumentsRequest;
}

export interface ListEpcisEventsRequest {
    kvintaOperationRequestListEpcisEventsRequest: KvintaOperationRequestListEpcisEventsRequest;
}

export interface MarkFailedMessageForRetryRequest {
    kvintaMessageKey: KvintaMessageKey;
}

export interface QueryMessageRecordsRequest {
    kvintaQueryRequestMessageRecordFilter: KvintaQueryRequestMessageRecordFilter;
}

export interface ReadChildrenRequest {
    kvintaOperationRequestGetRelativesRequest: KvintaOperationRequestGetRelativesRequest;
}

export interface ReadEpcEventByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadEpcisDocumentByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadEpcisDocumentRecordByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadEpcisDocumentRecordById1Request {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadEpcisEventByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadEpcisEventRecordByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadHierarchyEventsRequest {
    kvintaInlineObject2: KvintaInlineObject2;
}

export interface ReadLastStateForEpcRequest {
    kvintaOperationRequestGetLastStateRequest: KvintaOperationRequestGetLastStateRequest;
}

export interface ReadLocationAtTimestampRequest {
    kvintaOperationRequestGetRelevantChangesRequest: KvintaOperationRequestGetRelevantChangesRequest;
}

export interface ReadLocationChangingTimestampsRequest {
    kvintaOperationRequestGetRelevantChangesRequest: KvintaOperationRequestGetRelevantChangesRequest;
}

export interface ReadMessageContentRequest {
    kvintaMessageKey: KvintaMessageKey;
}

export interface ReadObjectRequest {
    id: string;
    objtype: string;
}

export interface ReadRelevantChangesLocationsRequest {
    kvintaOperationRequestGetRelevantChangesRequest: KvintaOperationRequestGetRelevantChangesRequest;
}

export interface SendTestEmailRequest {
    body: string;
}

export interface WriteObjectRequest {
    kvintaWriteObjectRequest: KvintaWriteObjectRequest;
}

export interface WriteObjectS3Request {
    kvintaWriteObjectRequest: KvintaWriteObjectRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async canCancelEpcisDocumentRaw(requestParameters: CanCancelEpcisDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseBoolean>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling canCancelEpcisDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/canCancelEpcisDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseBooleanFromJSON(jsonValue));
    }

    /**
     */
    async canCancelEpcisDocument(requestParameters: CanCancelEpcisDocumentRequest): Promise<KvintaOperationResponseBoolean> {
        const response = await this.canCancelEpcisDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async cancelEpcisMessageRaw(requestParameters: CancelEpcisMessageRequest): Promise<runtime.ApiResponse<KvintaMessageRecord>> {
        if (requestParameters.kvintaCancelEpcisDocumentRequest === null || requestParameters.kvintaCancelEpcisDocumentRequest === undefined) {
            throw new runtime.RequiredError('kvintaCancelEpcisDocumentRequest','Required parameter requestParameters.kvintaCancelEpcisDocumentRequest was null or undefined when calling cancelEpcisMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/cancelEpcisMessage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCancelEpcisDocumentRequestToJSON(requestParameters.kvintaCancelEpcisDocumentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMessageRecordFromJSON(jsonValue));
    }

    /**
     */
    async cancelEpcisMessage(requestParameters: CancelEpcisMessageRequest): Promise<KvintaMessageRecord> {
        const response = await this.cancelEpcisMessageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async captureEpcisDocumentRaw(requestParameters: CaptureEpcisDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestCaptureEpcisDocumentRequest === null || requestParameters.kvintaOperationRequestCaptureEpcisDocumentRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCaptureEpcisDocumentRequest','Required parameter requestParameters.kvintaOperationRequestCaptureEpcisDocumentRequest was null or undefined when calling captureEpcisDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/captureEpcisDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCaptureEpcisDocumentRequestToJSON(requestParameters.kvintaOperationRequestCaptureEpcisDocumentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async captureEpcisDocument(requestParameters: CaptureEpcisDocumentRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.captureEpcisDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async captureXmlEpcisDocumentRaw(requestParameters: CaptureXmlEpcisDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling captureXmlEpcisDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/xml';

        const response = await this.request({
            path: `/captureXmlEpcisDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async captureXmlEpcisDocument(requestParameters: CaptureXmlEpcisDocumentRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.captureXmlEpcisDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async checkConsistencyByDeliveryRaw(requestParameters: CheckConsistencyByDeliveryRequest): Promise<runtime.ApiResponse<KvintaConsistencyCheckResult>> {
        if (requestParameters.kvintaDocumentKey === null || requestParameters.kvintaDocumentKey === undefined) {
            throw new runtime.RequiredError('kvintaDocumentKey','Required parameter requestParameters.kvintaDocumentKey was null or undefined when calling checkConsistencyByDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/checkConsistencyByDelivery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaDocumentKeyToJSON(requestParameters.kvintaDocumentKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaConsistencyCheckResultFromJSON(jsonValue));
    }

    /**
     */
    async checkConsistencyByDelivery(requestParameters: CheckConsistencyByDeliveryRequest): Promise<KvintaConsistencyCheckResult> {
        const response = await this.checkConsistencyByDeliveryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async checkConsistencyByDeliveryV2Raw(requestParameters: CheckConsistencyByDeliveryV2Request): Promise<runtime.ApiResponse<KvintaDeliveryCheckResult>> {
        if (requestParameters.kvintaDocumentKey === null || requestParameters.kvintaDocumentKey === undefined) {
            throw new runtime.RequiredError('kvintaDocumentKey','Required parameter requestParameters.kvintaDocumentKey was null or undefined when calling checkConsistencyByDeliveryV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/checkConsistencyByDeliveryV2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaDocumentKeyToJSON(requestParameters.kvintaDocumentKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeliveryCheckResultFromJSON(jsonValue));
    }

    /**
     */
    async checkConsistencyByDeliveryV2(requestParameters: CheckConsistencyByDeliveryV2Request): Promise<KvintaDeliveryCheckResult> {
        const response = await this.checkConsistencyByDeliveryV2Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async checkConsistencyByShipmentRaw(requestParameters: CheckConsistencyByShipmentRequest): Promise<runtime.ApiResponse<KvintaConsistencyCheckResult>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling checkConsistencyByShipment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/checkConsistencyByShipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaConsistencyCheckResultFromJSON(jsonValue));
    }

    /**
     */
    async checkConsistencyByShipment(requestParameters: CheckConsistencyByShipmentRequest): Promise<KvintaConsistencyCheckResult> {
        const response = await this.checkConsistencyByShipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async computeAndStoreSsccContentRaw(requestParameters: ComputeAndStoreSsccContentRequest): Promise<runtime.ApiResponse<KvintaEpcsContent>> {
        if (requestParameters.kvintaEpcs === null || requestParameters.kvintaEpcs === undefined) {
            throw new runtime.RequiredError('kvintaEpcs','Required parameter requestParameters.kvintaEpcs was null or undefined when calling computeAndStoreSsccContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/computeAndStoreSsccContent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcsToJSON(requestParameters.kvintaEpcs),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaEpcsContentFromJSON(jsonValue));
    }

    /**
     */
    async computeAndStoreSsccContent(requestParameters: ComputeAndStoreSsccContentRequest): Promise<KvintaEpcsContent> {
        const response = await this.computeAndStoreSsccContentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async computeShipmentContentRaw(requestParameters: ComputeShipmentContentRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.kvintaComputeShipmentContentRequest === null || requestParameters.kvintaComputeShipmentContentRequest === undefined) {
            throw new runtime.RequiredError('kvintaComputeShipmentContentRequest','Required parameter requestParameters.kvintaComputeShipmentContentRequest was null or undefined when calling computeShipmentContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/computeShipmentContent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaComputeShipmentContentRequestToJSON(requestParameters.kvintaComputeShipmentContentRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async computeShipmentContent(requestParameters: ComputeShipmentContentRequest): Promise<string> {
        const response = await this.computeShipmentContentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createOwnerNotificationRaw(requestParameters: CreateOwnerNotificationRequest): Promise<runtime.ApiResponse<KvintaEpcisDocument>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createOwnerNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createOwnerNotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaEpcisDocumentFromJSON(jsonValue));
    }

    /**
     */
    async createOwnerNotification(requestParameters: CreateOwnerNotificationRequest): Promise<KvintaEpcisDocument> {
        const response = await this.createOwnerNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteObjectRaw(requestParameters: DeleteObjectRequest): Promise<runtime.ApiResponse<KvintaDeleteObjectResponseStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteObject.');
        }

        if (requestParameters.objtype === null || requestParameters.objtype === undefined) {
            throw new runtime.RequiredError('objtype','Required parameter requestParameters.objtype was null or undefined when calling deleteObject.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.objtype !== undefined) {
            queryParameters['objtype'] = requestParameters.objtype;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/uni-store/object`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaDeleteObjectResponseStatusFromJSON(jsonValue));
    }

    /**
     */
    async deleteObject(requestParameters: DeleteObjectRequest): Promise<KvintaDeleteObjectResponseStatus> {
        const response = await this.deleteObjectRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteObjectsRaw(requestParameters: DeleteObjectsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.kvintaInlineObject3 === null || requestParameters.kvintaInlineObject3 === undefined) {
            throw new runtime.RequiredError('kvintaInlineObject3','Required parameter requestParameters.kvintaInlineObject3 was null or undefined when calling deleteObjects.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/uni-store/deleteObjects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaInlineObject3ToJSON(requestParameters.kvintaInlineObject3),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteObjects(requestParameters: DeleteObjectsRequest): Promise<void> {
        await this.deleteObjectsRaw(requestParameters);
    }

    /**
     * Tries to find EPCIS events by given parent_id value.   In case there is no such event, the function will return OperationResponse(status = OK, data = [], message = \"No records found\")   Only in case there is an error, e.g. the database cannot be reached, it will return OperationResponse(status = ERROR, error = \"...\")
     * Tries to find EPCIS events by given parent_id value.   In case there is no such event, the function will return OperationResponse(status = OK, data = [], message = \"No records found\")   Only in case there is an error, e.g. the database cannot be reached, it will return OperationResponse(status = ERROR, error = \"...\")
     */
    async findEpcisEventRecordsByParentIdRaw(requestParameters: FindEpcisEventRecordsByParentIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling findEpcisEventRecordsByParentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findEpcisEventRecordsByParentId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     * Tries to find EPCIS events by given parent_id value.   In case there is no such event, the function will return OperationResponse(status = OK, data = [], message = \"No records found\")   Only in case there is an error, e.g. the database cannot be reached, it will return OperationResponse(status = ERROR, error = \"...\")
     * Tries to find EPCIS events by given parent_id value.   In case there is no such event, the function will return OperationResponse(status = OK, data = [], message = \"No records found\")   Only in case there is an error, e.g. the database cannot be reached, it will return OperationResponse(status = ERROR, error = \"...\")
     */
    async findEpcisEventRecordsByParentId(requestParameters: FindEpcisEventRecordsByParentIdRequest): Promise<KvintaOperationResponseListEpcisEventRecord> {
        const response = await this.findEpcisEventRecordsByParentIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findFirstGtinOfEpcisEventRaw(requestParameters: FindFirstGtinOfEpcisEventRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling findFirstGtinOfEpcisEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findFirstGtinOfEpcisEvent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async findFirstGtinOfEpcisEvent(requestParameters: FindFirstGtinOfEpcisEventRequest): Promise<KvintaOperationResponseString> {
        const response = await this.findFirstGtinOfEpcisEventRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findFirstGtinOfEpcisMessageRaw(requestParameters: FindFirstGtinOfEpcisMessageRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling findFirstGtinOfEpcisMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findFirstGtinOfEpcisMessage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async findFirstGtinOfEpcisMessage(requestParameters: FindFirstGtinOfEpcisMessageRequest): Promise<KvintaOperationResponseString> {
        const response = await this.findFirstGtinOfEpcisMessageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getEpcByBizTransactionRaw(requestParameters: GetEpcByBizTransactionRequest): Promise<runtime.ApiResponse<KvintaGetEpcByBizTransactionResponse>> {
        if (requestParameters.kvintaGetEpcByBizTransactionRequest === null || requestParameters.kvintaGetEpcByBizTransactionRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetEpcByBizTransactionRequest','Required parameter requestParameters.kvintaGetEpcByBizTransactionRequest was null or undefined when calling getEpcByBizTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getEpcByBizTransaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetEpcByBizTransactionRequestToJSON(requestParameters.kvintaGetEpcByBizTransactionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetEpcByBizTransactionResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEpcByBizTransaction(requestParameters: GetEpcByBizTransactionRequest): Promise<KvintaGetEpcByBizTransactionResponse> {
        const response = await this.getEpcByBizTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getEpcContentSummaryRaw(requestParameters: GetEpcContentSummaryRequest): Promise<runtime.ApiResponse<KvintaGetEpcContentSummaryResponse>> {
        if (requestParameters.kvintaGetEpcContentSummaryRequest === null || requestParameters.kvintaGetEpcContentSummaryRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetEpcContentSummaryRequest','Required parameter requestParameters.kvintaGetEpcContentSummaryRequest was null or undefined when calling getEpcContentSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getEpcContentSummary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetEpcContentSummaryRequestToJSON(requestParameters.kvintaGetEpcContentSummaryRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetEpcContentSummaryResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEpcContentSummary(requestParameters: GetEpcContentSummaryRequest): Promise<KvintaGetEpcContentSummaryResponse> {
        const response = await this.getEpcContentSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getEpcDirectChildrenRaw(requestParameters: GetEpcDirectChildrenRequest): Promise<runtime.ApiResponse<KvintaGetEpcDirectChildrenResponse>> {
        if (requestParameters.kvintaGetEpcDirectChildrenRequest === null || requestParameters.kvintaGetEpcDirectChildrenRequest === undefined) {
            throw new runtime.RequiredError('kvintaGetEpcDirectChildrenRequest','Required parameter requestParameters.kvintaGetEpcDirectChildrenRequest was null or undefined when calling getEpcDirectChildren.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getEpcDirectChildren`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaGetEpcDirectChildrenRequestToJSON(requestParameters.kvintaGetEpcDirectChildrenRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaGetEpcDirectChildrenResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEpcDirectChildren(requestParameters: GetEpcDirectChildrenRequest): Promise<KvintaGetEpcDirectChildrenResponse> {
        const response = await this.getEpcDirectChildrenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLastDeliveryAlarmRecordRaw(requestParameters: GetLastDeliveryAlarmRecordRequest): Promise<runtime.ApiResponse<KvintaAlarmRecord>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getLastDeliveryAlarmRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getLastDeliveryAlarmRecord`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaAlarmRecordFromJSON(jsonValue));
    }

    /**
     */
    async getLastDeliveryAlarmRecord(requestParameters: GetLastDeliveryAlarmRecordRequest): Promise<KvintaAlarmRecord> {
        const response = await this.getLastDeliveryAlarmRecordRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getMessageRecordStatusHistoryRaw(requestParameters: GetMessageRecordStatusHistoryRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListMessageStatusRecordV10>> {
        if (requestParameters.kvintaMessageKey === null || requestParameters.kvintaMessageKey === undefined) {
            throw new runtime.RequiredError('kvintaMessageKey','Required parameter requestParameters.kvintaMessageKey was null or undefined when calling getMessageRecordStatusHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/message-record/getMessageRecordStatusHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMessageKeyToJSON(requestParameters.kvintaMessageKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListMessageStatusRecordV10FromJSON(jsonValue));
    }

    /**
     */
    async getMessageRecordStatusHistory(requestParameters: GetMessageRecordStatusHistoryRequest): Promise<KvintaOperationResponseListMessageStatusRecordV10> {
        const response = await this.getMessageRecordStatusHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNestingLevelsRaw(requestParameters: GetNestingLevelsRequest): Promise<runtime.ApiResponse<{ [key: string]: number; }>> {
        if (requestParameters.kvintaEpcs === null || requestParameters.kvintaEpcs === undefined) {
            throw new runtime.RequiredError('kvintaEpcs','Required parameter requestParameters.kvintaEpcs was null or undefined when calling getNestingLevels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getNestingLevels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcsToJSON(requestParameters.kvintaEpcs),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getNestingLevels(requestParameters: GetNestingLevelsRequest): Promise<{ [key: string]: number; }> {
        const response = await this.getNestingLevelsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getParentsRaw(requestParameters: GetParentsRequest): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        if (requestParameters.kvintaEpcs === null || requestParameters.kvintaEpcs === undefined) {
            throw new runtime.RequiredError('kvintaEpcs','Required parameter requestParameters.kvintaEpcs was null or undefined when calling getParents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getParents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcsToJSON(requestParameters.kvintaEpcs),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getParents(requestParameters: GetParentsRequest): Promise<{ [key: string]: string; }> {
        const response = await this.getParentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async ignoreFailedMessageRaw(requestParameters: IgnoreFailedMessageRequest): Promise<runtime.ApiResponse<KvintaOperationResponseMessageStatusRecordV10>> {
        if (requestParameters.kvintaMessageKey === null || requestParameters.kvintaMessageKey === undefined) {
            throw new runtime.RequiredError('kvintaMessageKey','Required parameter requestParameters.kvintaMessageKey was null or undefined when calling ignoreFailedMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/message-record/ignoreFailedMessage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMessageKeyToJSON(requestParameters.kvintaMessageKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseMessageStatusRecordV10FromJSON(jsonValue));
    }

    /**
     */
    async ignoreFailedMessage(requestParameters: IgnoreFailedMessageRequest): Promise<KvintaOperationResponseMessageStatusRecordV10> {
        const response = await this.ignoreFailedMessageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listEpcEventsRaw(requestParameters: ListEpcEventsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListEpcEventsResponse>> {
        if (requestParameters.kvintaOperationRequestGetEpcEventListRequest === null || requestParameters.kvintaOperationRequestGetEpcEventListRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetEpcEventListRequest','Required parameter requestParameters.kvintaOperationRequestGetEpcEventListRequest was null or undefined when calling listEpcEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/query/listEpcEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetEpcEventListRequestToJSON(requestParameters.kvintaOperationRequestGetEpcEventListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListEpcEventsResponseFromJSON(jsonValue));
    }

    /**
     */
    async listEpcEvents(requestParameters: ListEpcEventsRequest): Promise<KvintaOperationResponseListEpcEventsResponse> {
        const response = await this.listEpcEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listEpcisDocumentsRaw(requestParameters: ListEpcisDocumentsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestListEpcisDocumentsRequest === null || requestParameters.kvintaOperationRequestListEpcisDocumentsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListEpcisDocumentsRequest','Required parameter requestParameters.kvintaOperationRequestListEpcisDocumentsRequest was null or undefined when calling listEpcisDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listEpcisDocuments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListEpcisDocumentsRequestToJSON(requestParameters.kvintaOperationRequestListEpcisDocumentsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async listEpcisDocuments(requestParameters: ListEpcisDocumentsRequest): Promise<KvintaOperationResponseGetListResultEpcisDocumentRecord> {
        const response = await this.listEpcisDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listEpcisEventsRaw(requestParameters: ListEpcisEventsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestListEpcisEventsRequest === null || requestParameters.kvintaOperationRequestListEpcisEventsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListEpcisEventsRequest','Required parameter requestParameters.kvintaOperationRequestListEpcisEventsRequest was null or undefined when calling listEpcisEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listEpcisEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListEpcisEventsRequestToJSON(requestParameters.kvintaOperationRequestListEpcisEventsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     */
    async listEpcisEvents(requestParameters: ListEpcisEventsRequest): Promise<KvintaOperationResponseGetListResultEpcisEventRecord> {
        const response = await this.listEpcisEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async markFailedMessageForRetryRaw(requestParameters: MarkFailedMessageForRetryRequest): Promise<runtime.ApiResponse<KvintaOperationResponseMessageStatusRecordV10>> {
        if (requestParameters.kvintaMessageKey === null || requestParameters.kvintaMessageKey === undefined) {
            throw new runtime.RequiredError('kvintaMessageKey','Required parameter requestParameters.kvintaMessageKey was null or undefined when calling markFailedMessageForRetry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/message-record/markFailedMessageForRetry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMessageKeyToJSON(requestParameters.kvintaMessageKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseMessageStatusRecordV10FromJSON(jsonValue));
    }

    /**
     */
    async markFailedMessageForRetry(requestParameters: MarkFailedMessageForRetryRequest): Promise<KvintaOperationResponseMessageStatusRecordV10> {
        const response = await this.markFailedMessageForRetryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async queryMessageRecordsRaw(requestParameters: QueryMessageRecordsRequest): Promise<runtime.ApiResponse<KvintaQueryResultMessageRecord>> {
        if (requestParameters.kvintaQueryRequestMessageRecordFilter === null || requestParameters.kvintaQueryRequestMessageRecordFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestMessageRecordFilter','Required parameter requestParameters.kvintaQueryRequestMessageRecordFilter was null or undefined when calling queryMessageRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/message-record/queryMessageRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestMessageRecordFilterToJSON(requestParameters.kvintaQueryRequestMessageRecordFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultMessageRecordFromJSON(jsonValue));
    }

    /**
     */
    async queryMessageRecords(requestParameters: QueryMessageRecordsRequest): Promise<KvintaQueryResultMessageRecord> {
        const response = await this.queryMessageRecordsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readChildrenRaw(requestParameters: ReadChildrenRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListString>> {
        if (requestParameters.kvintaOperationRequestGetRelativesRequest === null || requestParameters.kvintaOperationRequestGetRelativesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetRelativesRequest','Required parameter requestParameters.kvintaOperationRequestGetRelativesRequest was null or undefined when calling readChildren.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/query/readChildren`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetRelativesRequestToJSON(requestParameters.kvintaOperationRequestGetRelativesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListStringFromJSON(jsonValue));
    }

    /**
     */
    async readChildren(requestParameters: ReadChildrenRequest): Promise<KvintaOperationResponseListString> {
        const response = await this.readChildrenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcEventByIdRaw(requestParameters: ReadEpcEventByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcEvent>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcEventById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/query/readEpcEventById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcEventFromJSON(jsonValue));
    }

    /**
     */
    async readEpcEventById(requestParameters: ReadEpcEventByIdRequest): Promise<KvintaOperationResponseEpcEvent> {
        const response = await this.readEpcEventByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisDocumentByIdRaw(requestParameters: ReadEpcisDocumentByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocument>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisDocumentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisDocumentById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisDocumentById(requestParameters: ReadEpcisDocumentByIdRequest): Promise<KvintaOperationResponseEpcisDocument> {
        const response = await this.readEpcisDocumentByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisDocumentRecordByIdRaw(requestParameters: ReadEpcisDocumentRecordByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisDocumentRecordById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisDocumentRecordById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisDocumentRecordById(requestParameters: ReadEpcisDocumentRecordByIdRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.readEpcisDocumentRecordByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisDocumentRecordById1Raw(requestParameters: ReadEpcisDocumentRecordById1Request): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisDocumentRecordById1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisDocumentRecordBySha256`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisDocumentRecordById1(requestParameters: ReadEpcisDocumentRecordById1Request): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.readEpcisDocumentRecordById1Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisEventByIdRaw(requestParameters: ReadEpcisEventByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisEvent>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisEventById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisEventById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisEventFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisEventById(requestParameters: ReadEpcisEventByIdRequest): Promise<KvintaOperationResponseEpcisEvent> {
        const response = await this.readEpcisEventByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisEventRecordByIdRaw(requestParameters: ReadEpcisEventRecordByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisEventRecordById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisEventRecordById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisEventRecordById(requestParameters: ReadEpcisEventRecordByIdRequest): Promise<KvintaOperationResponseEpcisEventRecord> {
        const response = await this.readEpcisEventRecordByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readHierarchyEventsRaw(requestParameters: ReadHierarchyEventsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseHierarchyEvents>> {
        if (requestParameters.kvintaInlineObject2 === null || requestParameters.kvintaInlineObject2 === undefined) {
            throw new runtime.RequiredError('kvintaInlineObject2','Required parameter requestParameters.kvintaInlineObject2 was null or undefined when calling readHierarchyEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/query/readHierarchyEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaInlineObject2ToJSON(requestParameters.kvintaInlineObject2),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseHierarchyEventsFromJSON(jsonValue));
    }

    /**
     */
    async readHierarchyEvents(requestParameters: ReadHierarchyEventsRequest): Promise<KvintaOperationResponseHierarchyEvents> {
        const response = await this.readHierarchyEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readLastStateForEpcRaw(requestParameters: ReadLastStateForEpcRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListLastState>> {
        if (requestParameters.kvintaOperationRequestGetLastStateRequest === null || requestParameters.kvintaOperationRequestGetLastStateRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetLastStateRequest','Required parameter requestParameters.kvintaOperationRequestGetLastStateRequest was null or undefined when calling readLastStateForEpc.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/query/readLastStateForEpc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetLastStateRequestToJSON(requestParameters.kvintaOperationRequestGetLastStateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListLastStateFromJSON(jsonValue));
    }

    /**
     */
    async readLastStateForEpc(requestParameters: ReadLastStateForEpcRequest): Promise<KvintaOperationResponseListLastState> {
        const response = await this.readLastStateForEpcRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readLocationAtTimestampRaw(requestParameters: ReadLocationAtTimestampRequest): Promise<runtime.ApiResponse<KvintaOperationResponseLocation>> {
        if (requestParameters.kvintaOperationRequestGetRelevantChangesRequest === null || requestParameters.kvintaOperationRequestGetRelevantChangesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetRelevantChangesRequest','Required parameter requestParameters.kvintaOperationRequestGetRelevantChangesRequest was null or undefined when calling readLocationAtTimestamp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/query/readLocationAtTimestamp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetRelevantChangesRequestToJSON(requestParameters.kvintaOperationRequestGetRelevantChangesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseLocationFromJSON(jsonValue));
    }

    /**
     */
    async readLocationAtTimestamp(requestParameters: ReadLocationAtTimestampRequest): Promise<KvintaOperationResponseLocation> {
        const response = await this.readLocationAtTimestampRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readLocationChangingTimestampsRaw(requestParameters: ReadLocationChangingTimestampsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListLong>> {
        if (requestParameters.kvintaOperationRequestGetRelevantChangesRequest === null || requestParameters.kvintaOperationRequestGetRelevantChangesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetRelevantChangesRequest','Required parameter requestParameters.kvintaOperationRequestGetRelevantChangesRequest was null or undefined when calling readLocationChangingTimestamps.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/query/readLocationChangingTimestamps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetRelevantChangesRequestToJSON(requestParameters.kvintaOperationRequestGetRelevantChangesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListLongFromJSON(jsonValue));
    }

    /**
     */
    async readLocationChangingTimestamps(requestParameters: ReadLocationChangingTimestampsRequest): Promise<KvintaOperationResponseListLong> {
        const response = await this.readLocationChangingTimestampsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readMessageContentRaw(requestParameters: ReadMessageContentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaMessageKey === null || requestParameters.kvintaMessageKey === undefined) {
            throw new runtime.RequiredError('kvintaMessageKey','Required parameter requestParameters.kvintaMessageKey was null or undefined when calling readMessageContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/message-record/readMessageContent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMessageKeyToJSON(requestParameters.kvintaMessageKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     */
    async readMessageContent(requestParameters: ReadMessageContentRequest): Promise<KvintaOperationResponseString> {
        const response = await this.readMessageContentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readObjectRaw(requestParameters: ReadObjectRequest): Promise<runtime.ApiResponse<KvintaReadObjectResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling readObject.');
        }

        if (requestParameters.objtype === null || requestParameters.objtype === undefined) {
            throw new runtime.RequiredError('objtype','Required parameter requestParameters.objtype was null or undefined when calling readObject.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.objtype !== undefined) {
            queryParameters['objtype'] = requestParameters.objtype;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/uni-store/object`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaReadObjectResponseFromJSON(jsonValue));
    }

    /**
     */
    async readObject(requestParameters: ReadObjectRequest): Promise<KvintaReadObjectResponse> {
        const response = await this.readObjectRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readRelevantChangesLocationsRaw(requestParameters: ReadRelevantChangesLocationsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetRelevantChangesLocationsResponse>> {
        if (requestParameters.kvintaOperationRequestGetRelevantChangesRequest === null || requestParameters.kvintaOperationRequestGetRelevantChangesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestGetRelevantChangesRequest','Required parameter requestParameters.kvintaOperationRequestGetRelevantChangesRequest was null or undefined when calling readRelevantChangesLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/query/readRelevantChangesLocations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestGetRelevantChangesRequestToJSON(requestParameters.kvintaOperationRequestGetRelevantChangesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetRelevantChangesLocationsResponseFromJSON(jsonValue));
    }

    /**
     */
    async readRelevantChangesLocations(requestParameters: ReadRelevantChangesLocationsRequest): Promise<KvintaOperationResponseGetRelevantChangesLocationsResponse> {
        const response = await this.readRelevantChangesLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sendTestEmailRaw(requestParameters: SendTestEmailRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling sendTestEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sendTestEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async sendTestEmail(requestParameters: SendTestEmailRequest): Promise<string> {
        const response = await this.sendTestEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeObjectRaw(requestParameters: WriteObjectRequest): Promise<runtime.ApiResponse<KvintaWriteObjectResponse>> {
        if (requestParameters.kvintaWriteObjectRequest === null || requestParameters.kvintaWriteObjectRequest === undefined) {
            throw new runtime.RequiredError('kvintaWriteObjectRequest','Required parameter requestParameters.kvintaWriteObjectRequest was null or undefined when calling writeObject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/uni-store/object`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaWriteObjectRequestToJSON(requestParameters.kvintaWriteObjectRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaWriteObjectResponseFromJSON(jsonValue));
    }

    /**
     */
    async writeObject(requestParameters: WriteObjectRequest): Promise<KvintaWriteObjectResponse> {
        const response = await this.writeObjectRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeObjectS3Raw(requestParameters: WriteObjectS3Request): Promise<runtime.ApiResponse<KvintaWriteObjectResponse>> {
        if (requestParameters.kvintaWriteObjectRequest === null || requestParameters.kvintaWriteObjectRequest === undefined) {
            throw new runtime.RequiredError('kvintaWriteObjectRequest','Required parameter requestParameters.kvintaWriteObjectRequest was null or undefined when calling writeObjectS3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/uni-store/object-s3`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaWriteObjectRequestToJSON(requestParameters.kvintaWriteObjectRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaWriteObjectResponseFromJSON(jsonValue));
    }

    /**
     */
    async writeObjectS3(requestParameters: WriteObjectS3Request): Promise<KvintaWriteObjectResponse> {
        const response = await this.writeObjectS3Raw(requestParameters);
        return await response.value();
    }

}
