/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisOutboundFilter,
    KvintaEpcisOutboundFilterFromJSON,
    KvintaEpcisOutboundFilterFromJSONTyped,
    KvintaEpcisOutboundFilterToJSON,
    KvintaOwnerNotificationConfig,
    KvintaOwnerNotificationConfigFromJSON,
    KvintaOwnerNotificationConfigFromJSONTyped,
    KvintaOwnerNotificationConfigToJSON,
    KvintaWorkflowConfiguration,
    KvintaWorkflowConfigurationFromJSON,
    KvintaWorkflowConfigurationFromJSONTyped,
    KvintaWorkflowConfigurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisOutboundConfig
 */
export interface KvintaEpcisOutboundConfig {
    /**
     * 
     * @type {Array<KvintaEpcisOutboundFilter>}
     * @memberof KvintaEpcisOutboundConfig
     */
    filters?: Array<KvintaEpcisOutboundFilter> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisOutboundConfig
     */
    workflowType?: string | null;
    /**
     * 
     * @type {KvintaWorkflowConfiguration}
     * @memberof KvintaEpcisOutboundConfig
     */
    workflowOptions?: KvintaWorkflowConfiguration | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaEpcisOutboundConfig
     */
    queued: boolean;
    /**
     * 
     * @type {KvintaOwnerNotificationConfig}
     * @memberof KvintaEpcisOutboundConfig
     */
    ownerNotification?: KvintaOwnerNotificationConfig | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaEpcisOutboundConfig
     */
    includeLocationAndDisposition: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisOutboundConfig
     */
    defaultSapQueueMessageSender?: string | null;
}

export function KvintaEpcisOutboundConfigFromJSON(json: any): KvintaEpcisOutboundConfig {
    return KvintaEpcisOutboundConfigFromJSONTyped(json, false);
}

export function KvintaEpcisOutboundConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisOutboundConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filters': !exists(json, 'filters') ? undefined : (json['filters'] === null ? null : (json['filters'] as Array<any>).map(KvintaEpcisOutboundFilterFromJSON)),
        'workflowType': !exists(json, 'workflowType') ? undefined : json['workflowType'],
        'workflowOptions': !exists(json, 'workflowOptions') ? undefined : KvintaWorkflowConfigurationFromJSON(json['workflowOptions']),
        'queued': json['queued'],
        'ownerNotification': !exists(json, 'ownerNotification') ? undefined : KvintaOwnerNotificationConfigFromJSON(json['ownerNotification']),
        'includeLocationAndDisposition': json['includeLocationAndDisposition'],
        'defaultSapQueueMessageSender': !exists(json, 'defaultSapQueueMessageSender') ? undefined : json['defaultSapQueueMessageSender'],
    };
}

export function KvintaEpcisOutboundConfigToJSON(value?: KvintaEpcisOutboundConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filters': value.filters === undefined ? undefined : (value.filters === null ? null : (value.filters as Array<any>).map(KvintaEpcisOutboundFilterToJSON)),
        'workflowType': value.workflowType,
        'workflowOptions': KvintaWorkflowConfigurationToJSON(value.workflowOptions),
        'queued': value.queued,
        'ownerNotification': KvintaOwnerNotificationConfigToJSON(value.ownerNotification),
        'includeLocationAndDisposition': value.includeLocationAndDisposition,
        'defaultSapQueueMessageSender': value.defaultSapQueueMessageSender,
    };
}


