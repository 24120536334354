/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaSingleMobileTransactionConfig
 */
export interface KvintaSingleMobileTransactionConfig {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    icon?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    showConfirmDialog: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    executorType: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    settings: { [key: string]: object; };
}

export function KvintaSingleMobileTransactionConfigFromJSON(json: any): KvintaSingleMobileTransactionConfig {
    return KvintaSingleMobileTransactionConfigFromJSONTyped(json, false);
}

export function KvintaSingleMobileTransactionConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSingleMobileTransactionConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'name': json['name'],
        'description': json['description'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'showConfirmDialog': json['showConfirmDialog'],
        'executorType': json['executorType'],
        'settings': json['settings'],
    };
}

export function KvintaSingleMobileTransactionConfigToJSON(value?: KvintaSingleMobileTransactionConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'name': value.name,
        'description': value.description,
        'icon': value.icon,
        'showConfirmDialog': value.showConfirmDialog,
        'executorType': value.executorType,
        'settings': value.settings,
    };
}


