/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaResourceType {
    EpcisXmlMessage = 'EPCIS_XML_MESSAGE',
    EpcisJsonMessage = 'EPCIS_JSON_MESSAGE',
    EpcisDocument = 'EPCIS_DOCUMENT',
    EpcisDocumentCancellation = 'EPCIS_DOCUMENT_CANCELLATION',
    CaptureEpcisEvent = 'CAPTURE_EPCIS_EVENT',
    EpcisEvent = 'EPCIS_EVENT',
    DeliveryNote = 'DELIVERY_NOTE',
    SerialNumbersList = 'SERIAL_NUMBERS_LIST',
    MasterdataSnapshot = 'MASTERDATA_SNAPSHOT',
    DocumentsSnapshot = 'DOCUMENTS_SNAPSHOT',
    SoapMessage = 'SOAP_MESSAGE',
    Text = 'TEXT',
    SerialNumberOrder = 'SERIAL_NUMBER_ORDER',
    Execution = 'EXECUTION',
    ExecutionStatus = 'EXECUTION_STATUS',
    ExecutionStatusRecord = 'EXECUTION_STATUS_RECORD',
    Epc = 'EPC',
    ProductionOrder = 'PRODUCTION_ORDER',
    SuzDropoutReport = 'SUZ_DROPOUT_REPORT',
    SuzUtilisationReport = 'SUZ_UTILISATION_REPORT',
    SuzAggregationReport = 'SUZ_AGGREGATION_REPORT',
    MptAggregationReport = 'MPT_AGGREGATION_REPORT',
    MptCirculationReport = 'MPT_CIRCULATION_REPORT',
    MptImportReport = 'MPT_IMPORT_REPORT',
    RegUzAic = 'REG_UZ_AIC',
    RegUzAggMl = 'REG_UZ_AGG_ML',
    RegUzOmsAggr = 'REG_UZ_OMS_AGGR',
    RegUzHierarchy = 'REG_UZ_HIERARCHY',
    RegRuGismtAggr = 'REG_RU_GISMT_AGGR',
    RegAeTatShip = 'REG_AE_TAT_SHIP',
    NismtHierarchyRequest = 'NISMT_HIERARCHY_REQUEST',
    RegAeReceivingReturn = 'REG_AE_RECEIVING_RETURN',
    RegAeShippingReturn = 'REG_AE_SHIPPING_RETURN',
    RegAeReceiving = 'REG_AE_RECEIVING',
    RegAeShipping = 'REG_AE_SHIPPING',
    RegAePacking = 'REG_AE_PACKING',
    RegAeUnpacking = 'REG_AE_UNPACKING',
    RegAeCommission = 'REG_AE_COMMISSION'
}

export function KvintaResourceTypeFromJSON(json: any): KvintaResourceType {
    return KvintaResourceTypeFromJSONTyped(json, false);
}

export function KvintaResourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaResourceType {
    return json as KvintaResourceType;
}

export function KvintaResourceTypeToJSON(value?: KvintaResourceType | null): any {
    return value as any;
}

