/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMessageAttributes,
    KvintaMessageAttributesFromJSON,
    KvintaMessageAttributesFromJSONTyped,
    KvintaMessageAttributesToJSON,
    KvintaMessageKey,
    KvintaMessageKeyFromJSON,
    KvintaMessageKeyFromJSONTyped,
    KvintaMessageKeyToJSON,
    KvintaMessageStatusRecordV10,
    KvintaMessageStatusRecordV10FromJSON,
    KvintaMessageStatusRecordV10FromJSONTyped,
    KvintaMessageStatusRecordV10ToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMessageRecord
 */
export interface KvintaMessageRecord {
    /**
     * 
     * @type {KvintaMessageKey}
     * @memberof KvintaMessageRecord
     */
    key: KvintaMessageKey;
    /**
     * 
     * @type {KvintaMessageAttributes}
     * @memberof KvintaMessageRecord
     */
    attributes: KvintaMessageAttributes;
    /**
     * 
     * @type {KvintaMessageStatusRecordV10}
     * @memberof KvintaMessageRecord
     */
    lastStatus?: KvintaMessageStatusRecordV10 | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageRecord
     */
    sha256?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageRecord
     */
    path?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaMessageRecord
     */
    timestamp?: number | null;
}

export function KvintaMessageRecordFromJSON(json: any): KvintaMessageRecord {
    return KvintaMessageRecordFromJSONTyped(json, false);
}

export function KvintaMessageRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMessageRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': KvintaMessageKeyFromJSON(json['key']),
        'attributes': KvintaMessageAttributesFromJSON(json['attributes']),
        'lastStatus': !exists(json, 'lastStatus') ? undefined : KvintaMessageStatusRecordV10FromJSON(json['lastStatus']),
        'sha256': !exists(json, 'sha256') ? undefined : json['sha256'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
    };
}

export function KvintaMessageRecordToJSON(value?: KvintaMessageRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': KvintaMessageKeyToJSON(value.key),
        'attributes': KvintaMessageAttributesToJSON(value.attributes),
        'lastStatus': KvintaMessageStatusRecordV10ToJSON(value.lastStatus),
        'sha256': value.sha256,
        'path': value.path,
        'timestamp': value.timestamp,
    };
}


