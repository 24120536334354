/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGetEpcByBizTransactionBizTransaction,
    KvintaGetEpcByBizTransactionBizTransactionFromJSON,
    KvintaGetEpcByBizTransactionBizTransactionFromJSONTyped,
    KvintaGetEpcByBizTransactionBizTransactionToJSON,
    KvintaReturnEpcFormat,
    KvintaReturnEpcFormatFromJSON,
    KvintaReturnEpcFormatFromJSONTyped,
    KvintaReturnEpcFormatToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetEpcByBizTransactionRequest
 */
export interface KvintaGetEpcByBizTransactionRequest {
    /**
     * 
     * @type {KvintaGetEpcByBizTransactionBizTransaction}
     * @memberof KvintaGetEpcByBizTransactionRequest
     */
    bizTransaction: KvintaGetEpcByBizTransactionBizTransaction;
    /**
     * 
     * @type {KvintaReturnEpcFormat}
     * @memberof KvintaGetEpcByBizTransactionRequest
     */
    format: KvintaReturnEpcFormat;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetEpcByBizTransactionRequest
     */
    timestamp?: number | null;
}

export function KvintaGetEpcByBizTransactionRequestFromJSON(json: any): KvintaGetEpcByBizTransactionRequest {
    return KvintaGetEpcByBizTransactionRequestFromJSONTyped(json, false);
}

export function KvintaGetEpcByBizTransactionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcByBizTransactionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bizTransaction': KvintaGetEpcByBizTransactionBizTransactionFromJSON(json['bizTransaction']),
        'format': KvintaReturnEpcFormatFromJSON(json['format']),
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
    };
}

export function KvintaGetEpcByBizTransactionRequestToJSON(value?: KvintaGetEpcByBizTransactionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bizTransaction': KvintaGetEpcByBizTransactionBizTransactionToJSON(value.bizTransaction),
        'format': KvintaReturnEpcFormatToJSON(value.format),
        'timestamp': value.timestamp,
    };
}


